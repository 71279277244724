import ProfileService from 'services/ProfileService'
import { BaseResponse, UserDataResponse } from 'services/types'

/// TODO: 2FA: Remove fetchUserSettingData after BE update
export const fetchUserData = async (): Promise<BaseResponse<UserDataResponse>> => {
  const result = await ProfileService.getUserData()
  const response2FAUserSetting = await ProfileService.getUserSettingData()

  return {
    ...result,
    data: {
      ...result.data,
      settings: {
        ...result.data?.settings,
        enable2FA: response2FAUserSetting?.data?.is_totp_enable > 0 || false,
      },
    },
  }
}

