import { useRequest } from 'hooks/useRequest'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CashbackService from 'services/CashbackService'
import { AppState, useAppDispatch } from 'state'
import { useAuth } from 'state/auth/hooks'
import { getCashbackEndDateTimestamp } from 'views/Cashback/utils'
import { updateAvailableCashbacks, updateNextCashbackTime, updateTotalEarnedCashbacks } from './actions'

export const useAvailableCashbacks = () => {
  const { execute } = useRequest()
  const dispatch = useAppDispatch()
  const availableCashbacks = useSelector((state: AppState) => state.cashback.availableCashbacks)

  const fetchAvailableCashbacks = useCallback(async () => {
    const response = await execute(CashbackService.getCashbackAvailableClaim())
    if (response?.data) dispatch(updateAvailableCashbacks({ availableCashbacks: response.data.items || [] }))
  }, [])

  return useMemo(
    () => ({
      availableCashbacks,
      fetchAvailableCashbacks,
    }),
    [availableCashbacks, fetchAvailableCashbacks],
  )
}

export const useTotalEarnedCashback = () => {
  const { execute } = useRequest()
  const dispatch = useAppDispatch()
  const totalEarnedCashbacks = useSelector((state: AppState) => state.cashback.totalEarnedCashbacks)

  const fetchTotalEarnedCashbacks = useCallback(async () => {
    const response = await execute(CashbackService.getCashbackTotalEarned())
    if (response.data) {
      if (response.data.items?.length > 0)
        dispatch(updateTotalEarnedCashbacks({ totalEarnedCashbacks: response.data.items }))
      else dispatch(updateTotalEarnedCashbacks({ totalEarnedCashbacks: [] }))
    }
  }, [])

  return useMemo(
    () => ({
      totalEarnedCashbacks,
      fetchTotalEarnedCashbacks,
    }),
    [totalEarnedCashbacks, fetchTotalEarnedCashbacks],
  )
}

export const useNextCaskbackTime = () => {
  const nextCashbackTime = useSelector((state: AppState) => state.cashback.nextCashbackTime)
  const serverTimeMargin = useSelector((state: AppState) => state.app.serverTimeMargin)
  const dispatch = useAppDispatch()
  const { execute } = useRequest()

  const fetchNextCashbackTime = async () => {
    const response = await execute(CashbackService.getCashbackEndDate())

    if (response && response.data) {
      const cashbackEndDateTimestamp = getCashbackEndDateTimestamp(response.data.timestamps, serverTimeMargin)
      dispatch(updateNextCashbackTime({ nextCashbackTime: cashbackEndDateTimestamp }))
    }
  }

  return useMemo(
    () => ({
      nextCashbackTime,
      fetchNextCashbackTime,
    }),
    [nextCashbackTime, fetchNextCashbackTime],
  )
}

export const useCashbackInfoes = () => {
  const { availableCashbacks } = useAvailableCashbacks()
  const { nextCashbackTime } = useNextCaskbackTime()

  return useMemo(() => ({ availableCashbacks, nextCashbackTime }), [availableCashbacks, nextCashbackTime])
}

export const useCashbackUpdater = () => {
  const { fetchAvailableCashbacks } = useAvailableCashbacks()
  const { fetchNextCashbackTime, nextCashbackTime } = useNextCaskbackTime()

  const { isSigned } = useAuth()
  const isAvaiableCaskbackFetchedRef = useRef(false)

  useEffect(() => {
    if (!isSigned) {
      isAvaiableCaskbackFetchedRef.current = false
      return
    }
    if (isAvaiableCaskbackFetchedRef.current) return

    fetchAvailableCashbacks()
    fetchNextCashbackTime()
    isAvaiableCaskbackFetchedRef.current = true
  }, [isSigned])

  useEffect(() => {
    if (nextCashbackTime) {
      isAvaiableCaskbackFetchedRef.current = true
      const cashbackExpiredDuration = nextCashbackTime - Date.now()

      const timeout = setTimeout(() => {
        fetchAvailableCashbacks()
        fetchNextCashbackTime()
      }, cashbackExpiredDuration)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [nextCashbackTime])
}

export const useClaimCashback = () => {
  const [isClaiming, setIsClaiming] = useState(false)
  const { availableCashbacks } = useAvailableCashbacks()

  const handleClaimCashback = useCallback(
    async (callback: (isSuccessed: boolean) => void) => {
      setIsClaiming(true)
      const cashbackTransactionIds = availableCashbacks.map((transaction) => transaction.id)
      const response = await CashbackService.claim(cashbackTransactionIds)
      if (response.code === 'success') callback(true)

      setIsClaiming(false)
    },
    [availableCashbacks],
  )

  return useMemo(() => ({ isClaiming, handleClaimCashback }), [isClaiming, handleClaimCashback])
}
