import CompletedTransaction from 'components/TransactionToast/CompletedTransaction'
import PendingTransaction from 'components/TransactionToast/PendingTransaction'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { PendingTransaction as PendingTransactionStatus } from 'config/types/transaction'
import { useAnalytics } from 'hooks/useAnalytics'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { useEffect, useRef } from 'react'
import PaymentService from 'services/PaymentService'
import { parseDepositTransaction, parseWithdrawalTransaction } from 'services/mapper/utils'
import { useAppDispatch, useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { HunnyToast, showToast } from 'utils/toastify'
import { combineDepositHash, getDepositHashInfo } from 'utils/transaction'
import {
  addPendingDepositTransaction,
  clearPendingTransaction,
  removePendingDepositTransaction,
  removePendingWithdrawTransaction,
} from './action'

export const usePollPendingTransaction = () => {
  const dispatch = useAppDispatch()
  const { isSigned, hasSession } = useAuth()
  const socket = usePrivateSocket()
  const toastIdsRef = useRef([])
  const { addMetaEvent } = useAnalytics()
  const pendingWithdrawTransactionCode = useAppSelector(
    (state) => state.transaction.pendingWithdrawTransactionCode || [],
  )
  const pendingDepositTransactionHash = useAppSelector((state) => state.transaction.pendingDepositTransactionHash || [])

  useEffect(() => {
    if (!isSigned) {
      if (!hasSession && (pendingWithdrawTransactionCode.length || pendingDepositTransactionHash.length)) {
        dispatch(clearPendingTransaction())
      }
    }
  }, [pendingWithdrawTransactionCode, pendingDepositTransactionHash, isSigned, hasSession])

  // TODO review it
  useEffect(() => {
    if (!isSigned) {
      return
    }

    const newPendingWithdrawCodes = pendingWithdrawTransactionCode.filter((code) => !toastIdsRef.current.includes(code))

    if (newPendingWithdrawCodes.length) {
      newPendingWithdrawCodes.forEach(async (code) => {
        const response = await PaymentService.getWithdrawal(code).call()
        if (response.data && PendingTransactionStatus.includes(response.data.status)) {
          showToast(<PendingTransaction transaction={response.data} />, {
            autoClose: false,
            toastId: code,
            onClose: () => {
              dispatch(removePendingWithdrawTransaction({ code }))
            },
          })
        } else {
          dispatch(removePendingWithdrawTransaction({ code }))
        }
      })

      toastIdsRef.current = [...toastIdsRef.current, ...newPendingWithdrawCodes]
    }
  }, [pendingWithdrawTransactionCode, isSigned])

  // TODO review it
  useEffect(() => {
    if (!isSigned) {
      return
    }

    const newPendingDepositHashs = pendingDepositTransactionHash.filter((hash) => !toastIdsRef.current.includes(hash))

    if (newPendingDepositHashs.length) {
      newPendingDepositHashs.forEach(async (combinedHash) => {
        const { token, hash } = getDepositHashInfo(combinedHash)

        const response = await PaymentService.getDeposit(hash, token.code, token.network).call()
        if (response.data && PendingTransactionStatus.includes(response.data.status)) {
          showToast(<PendingTransaction transaction={response.data} />, {
            autoClose: false,
            toastId: combinedHash,
            onClose: () => {
              dispatch(removePendingDepositTransaction({ hash: combinedHash }))
            },
          })
        } else {
          dispatch(removePendingDepositTransaction({ hash: combinedHash }))
        }
      })

      toastIdsRef.current = [...toastIdsRef.current, ...newPendingDepositHashs]
    }
  }, [pendingDepositTransactionHash, isSigned])

  useEffect(() => {
    const removedPendingTxnCodes = toastIdsRef.current.filter((code) => !pendingWithdrawTransactionCode.includes(code))
    if (removedPendingTxnCodes.length) {
      removedPendingTxnCodes.forEach((code) => {
        HunnyToast.dismiss(code)
      })
    }
  }, [pendingWithdrawTransactionCode])

  useEffect(() => {
    const removedPendingDepositHash = toastIdsRef.current.filter(
      (code) => !pendingDepositTransactionHash.includes(code),
    )
    if (removedPendingDepositHash.length) {
      removedPendingDepositHash.forEach((hash) => {
        HunnyToast.dismiss(hash)
      })
    }
  }, [pendingDepositTransactionHash])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }
    socket.on('withdrawal.succeeded', (data) => {
      if (data) {
        const transaction = parseWithdrawalTransaction(data)
        dispatch(removePendingWithdrawTransaction({ code: transaction.id.toString() }))
        showToast(<CompletedTransaction transaction={transaction} />, {}, 'success')
      }
    })

    return () => {
      if (socket) {
        socket.off('withdrawal.succeeded')
      }
    }
  }, [isSigned, socket])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }
    socket.on('withdrawal.failed', (data) => {
      if (data) {
        const transaction = parseWithdrawalTransaction(data)
        dispatch(removePendingWithdrawTransaction({ code: transaction.id.toString() }))
        showToast(<CompletedTransaction transaction={transaction} />, {}, 'error')
      }
    })

    return () => {
      if (socket) {
        socket.off('withdrawal.failed')
      }
    }
  }, [isSigned, socket])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }
    socket.on('deposit.pending', (data) => {
      if (data) {
        if (UsableFiatCurrencies.includes(data.currency)) return
        const transaction = parseDepositTransaction(data)
        const token = tokens[transaction.network][transaction.currency]
        dispatch(addPendingDepositTransaction({ hash: combineDepositHash(transaction.txnHash, token) }))
      }
    })

    return () => {
      if (socket) {
        socket.off('deposit.pending')
      }
    }
  }, [isSigned, socket])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }
    socket.on('deposit.confirmed', (data) => {
      if (data) {
        const transaction = parseDepositTransaction(data)
        const token = tokens[transaction.network][transaction.currency]
        dispatch(removePendingDepositTransaction({ hash: combineDepositHash(transaction.txnHash, token) }))
        addMetaEvent('Purchase', {
          currency: `${token.name}|${ChainIdEnum[token.network]}`,
          amount: transaction.value,
        })

        if (transaction.isFtd) {
          addMetaEvent('FirstDepositArrival', {
            currency: `${token.name}|${ChainIdEnum[token.network]}`,
            amount: transaction.value,
          })
        }
        showToast(<CompletedTransaction transaction={transaction} />, {}, 'success')
      }
    })

    return () => {
      if (socket) {
        socket.off('deposit.confirmed')
      }
    }
  }, [isSigned, socket])
}

