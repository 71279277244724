import { FetchingStatus } from 'config/constants'
import { useAnalytics } from 'hooks/useAnalytics'
import { useEffect, useState } from 'react'
import { getUserLevelStats } from 'services/mapper/utils'
import { UserDataResponse } from 'services/types'
import { useAppDispatch } from 'state'
import { setInitializeSiteStatus } from 'state/app/actions'
import { useTiers } from 'state/app/hooks'
import { updateEmail, updateTelegramId } from 'state/auth/action'
import { fetchUserData } from 'state/auth/calls/fetchUserData'
import { useAuth } from 'state/auth/hooks'
import { updateUserBonuses, updateUserUnlockableHUSDAmount } from 'state/bonus/actions'
import { updateNotificationUnreadAmount } from 'state/notification/actions'
import { initialProfile, updateUserSetting } from 'state/profile/actions'
import { useUpdateUserLuckySpin } from 'state/profile/hooks'
import { forkjoinRequest } from 'utils/requestHelper'

const InitialUserProfile = ({ children }) => {
  const dispatch = useAppDispatch()
  const [fetchingMetaStatus, setFetchingMetaStatus] = useState(FetchingStatus.Fetching)
  const { isSigned } = useAuth()

  const tiers = useTiers()
  const fetchUserLuckSpin = useUpdateUserLuckySpin()

  const { recordEvent, addWonderPushProperty } = useAnalytics()

  useEffect(() => {
    if (!isSigned) {
      return
    }

    const fetch = async () => {
      const [user] = await forkjoinRequest([fetchUserData(), fetchUserLuckSpin()])
      const { userBonuses, profile, balances, welcomePackage, notificationUnreadCount, bonusBalances, settings } =
        user.data as UserDataResponse
      if (userBonuses?.length > 0) {
        dispatch(
          updateUserBonuses({
            userBonuses,
          }),
        )
      }

      if (profile.email) dispatch(updateEmail({ email: profile.email }))

      const dataTier = tiers.find((tier) => tier.id === profile.tier)
      const wager = getUserLevelStats(tiers, dataTier, profile.totalWager)

      recordEvent('userDataLoaded', {
        vipLevel: dataTier.level,
      })

      if (profile) {
        addWonderPushProperty({
          int_level: dataTier.level,
          float_wager: wager.score,
          int_bet: Number(profile.totalBet),
          date_register: profile.registeredDate.getTime(),
        })
      }

      dispatch(
        initialProfile({
          data: {
            balances,
            profile: {
              tier: dataTier,
              wager: {
                ...wager,
                totalBet: profile.totalBet,
              },
              userCode: profile.userCode,
              avatar: profile.avatar,
              displayName: profile.displayName,
              totalBet: profile.totalBet,
              canUpdateDisplayNameAt: profile.canUpdateDisplayNameAt,
              registeredDate: profile.registeredDate,
              telegram: profile.telegram,
            },
            welcomePackage,
          },
        }),
      )

      dispatch(updateTelegramId(profile.telegram.id))

      dispatch(
        updateUserUnlockableHUSDAmount({
          unlockableBalance: bonusBalances.HUSD,
        }),
      )

      dispatch(
        updateNotificationUnreadAmount({
          personal: notificationUnreadCount?.personal || 0,
          system: notificationUnreadCount?.system || 0,
        }),
      )

      setFetchingMetaStatus(FetchingStatus.Fetched)
      dispatch(setInitializeSiteStatus({ status: FetchingStatus.Fetched }))

      dispatch(
        updateUserSetting({
          settings: {
            isDisableAnimationAirdrop: settings.disableAirdropAnimation,
            isHideName: settings.disablePublicDisplayName,
            isHideStatistic: settings.disableStatistic,
            isHideZeroBalance: settings.disableZeroBalance,
            isDisplayInFiat: settings.disableDisplayInFiat,
            selectedFiatCurrency: settings.selectedFiatCurrency,
            isActive2FA: settings.enable2FA,
            isEnable2FAForLogin: settings.isEnable2FAForLogin,
            isEnable2FAForWithdraw: settings.isEnable2FAForWithdraw,
            isEnable2FAForTip: settings.isEnable2FAForTip,
          },
        }),
      )
    }

    fetch()
  }, [isSigned, tiers])

  return fetchingMetaStatus !== FetchingStatus.Fetching && children
}

export default InitialUserProfile
