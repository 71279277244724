import { useRouter } from 'hooks/useRouter'
import { useAutoLoginTelegram, useBuildTeleAppData, useIsTelegramOrigin } from 'hooks/useTelegram'
import { useEffect, useMemo, useState } from 'react'
import { expandTeleApp, readyAppTele } from 'utils/telegram'

const TelegramBackButton = () => {
  const router = useRouter()
  const isTelegramApp = useIsTelegramOrigin()
  const backButton = useMemo(() => window && window?.Telegram?.WebApp?.BackButton, [isTelegramApp])

  useEffect(() => {
    if (!backButton) return

    if (router.pathname === '/') {
      if (backButton.isVisible) {
        backButton.hide()
      }
    } else if (!backButton.isVisible) {
      backButton.show()
      backButton.onClick(() => {
        window.history.go(-1)
      })
    }
  }, [router.pathname, backButton])

  useEffect(() => {
    readyAppTele()
    expandTeleApp()
  }, [])

  return null
}

const TelegramUpdater: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isTelegramApp = useIsTelegramOrigin()

  const login = useAutoLoginTelegram()

  const { webAppData, payload } = useBuildTeleAppData()
  const [loading, setLoading] = useState(true)

  const fetch = async () => {
    try {
      setLoading(true)
      if (webAppData && payload && isTelegramApp) {
        await login()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      {isTelegramApp && (
        <>
          <TelegramBackButton />
        </>
      )}

      {!loading && children}
    </>
  )
}

export default TelegramUpdater
