import langTR from 'resources/locales/tr.json'
import langHU from 'resources/locales/hu.json'
import langNL from 'resources/locales/nl.json'
import langJA from 'resources/locales/ja.json'
import langDE from 'resources/locales/de.json'
import langRU from 'resources/locales/ru.json'
import langPL from 'resources/locales/pl.json'
import langEN from 'resources/locales/en.json'
import langPTPT from 'resources/locales/pt-PT.json'
import langIT from 'resources/locales/it.json'
import langZHHANS from 'resources/locales/zh-Hans.json'
import langFR from 'resources/locales/fr.json'
import langBG from 'resources/locales/bg.json'
import langPTBR from 'resources/locales/pt-BR.json'
import langKO from 'resources/locales/ko.json'
import langVI from 'resources/locales/vi.json'
import langID from 'resources/locales/id.json'
import langTH from 'resources/locales/th.json'
import langES from 'resources/locales/es.json'
import langAR from 'resources/locales/ar.json'
import langZHHANT from 'resources/locales/zh-Hant.json'

const resources = {
	'th': { translation: langTH },
	'ar': { translation: langAR },
	'pl': { translation: langPL },
	'es': { translation: langES },
	'it': { translation: langIT },
	'ja': { translation: langJA },
	'nl': { translation: langNL },
	'de': { translation: langDE },
	'hu': { translation: langHU },
	'id': { translation: langID },
	'fr': { translation: langFR },
	'pt-PT': { translation: langPTPT },
	'tr': { translation: langTR },
	'pt-BR': { translation: langPTBR },
	'zh-Hans': { translation: langZHHANS },
	'bg': { translation: langBG },
	'ko': { translation: langKO },
	'vi': { translation: langVI },
	'ru': { translation: langRU },
	'zh-Hant': { translation: langZHHANT },
	'en': { translation: langEN }
}

export default resources
