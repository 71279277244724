import { ChainIdEnum } from 'config/constants/network'
import { useEffect } from 'react'
import { useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { useTokenSelected } from 'state/session/hooks'
import { useAnalytics } from './useAnalytics'

function useAnalyticsUser() {
  const { username, uid, authBy, isSigned } = useAuth()
  const tier = useAppSelector((state) => state.profile.tier)
  const wallet = useAppSelector((state) => state.auth.wallet)
  const { updateUserId, updateUserType, recordEvent } = useAnalytics()
  const selectToken = useTokenSelected()

  useEffect(() => {
    if (username && uid) {
      updateUserId(uid.toString())
      updateUserType('type', authBy)
    } else {
      updateUserId(null)
    }
  }, [username, uid])

  useEffect(() => {
    if (isSigned) {
      updateUserType('coin', `${ChainIdEnum[selectToken.network]} | ${selectToken.name}`)
      recordEvent('select_balance', {
        [ChainIdEnum[selectToken.network]]: selectToken.name,
      })
    }
  }, [isSigned, selectToken])

  useEffect(() => {
    if (tier?.id) {
      updateUserType('vip', tier.id.toString())
    }
  }, [tier?.id])

  useEffect(() => {
    if (tier?.id) {
      updateUserType('vip', tier.id.toString())
    }
  }, [tier?.id])

  useEffect(() => {
    if (wallet?.trackingName) updateUserType('wallet', wallet?.trackingName)
  }, [wallet])
}

export default useAnalyticsUser
