import MetaService from 'services/MetaService'
import { forkjoinRequest } from 'utils/requestHelper'
import { fetchTokenInUsdPrices } from './fetchPriceInUsd'

export async function fetchMetaData() {
  const [meta, country, wagerAdventure, prices] = await forkjoinRequest([
    MetaService.getMetaData(),
    MetaService.getClientCountry(),
    fetchTokenInUsdPrices(),
  ])
  return [meta, country, wagerAdventure, prices]
}
