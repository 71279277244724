import useDebounce from 'hooks/useDebounce'
import { useEffect, useRef } from 'react'
import { useAppStatus } from 'state/app/hooks'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import Box from './Box/Box'
import Flex from './Box/Flex'
import { BoxProps } from './Box/types'
import Image from './Image'

const Loader: React.FC<BoxProps> = () => {
  const { isFinishFetch } = useAppStatus()

  const isConnectedDebounce = useDebounce(isFinishFetch, 400)

  const loaderRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isFinishFetch) {
      loaderRef.current.style.width = '100%'
      return
    }

    loaderRef.current.style.width = '0%'

    const timeout1 = setTimeout(() => {
      loaderRef.current.style.width = '95%'
    }, 10)

    return () => {
      clearTimeout(timeout1)
    }
  }, [isFinishFetch])

  return (
    <StyledContainer
      alignItems="center"
      justifyContent="center"
      height="var(--screen-height) !important"
      width="100%"
      display="flex"
      position="fixed"
      zIndex="1000"
      background={colors.background}
      top="0px"
      $hide={isConnectedDebounce}
    >
      <StyledProcessLoader
        ref={loaderRef}
        left="0px"
        height="4px"
        background={colors.primary}
        position="fixed"
        top="0px"
      />

      <Box>
        <Image src="/logo-text.png" height={80} width={268.4} alt="logo" />
      </Box>
    </StyledContainer>
  )
}

const StyledProcessLoader = styled(Box)`
  transition: 0.5s;
  width: 0%;
`

const StyledContainer = styled(Flex)<{ $hide: boolean }>`
  z-index: 1000;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  pointer-events: ${({ $hide }) => ($hide ? 'none' : 'all')};
`

export default Loader
