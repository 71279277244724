import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import tokens from 'config/constants/tokens'
import { Token } from 'config/types'
import { Transaction, TransactionStatusEnum, TransactionTypeEnum } from 'config/types/transaction'
import { Trans } from 'react-i18next'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'

const CompletedTransaction: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
  const token = tokens[transaction.network][transaction.currency] as Token
  const displayAmount = getFullDisplayBalance(new BigNumber(transaction.value).minus(transaction.fee || 0), 0, 6)

  const color = transaction.status === TransactionStatusEnum.Succeeded ? colors.success : colors.error
  return (
    <Box minWidth="250px">
      <Flex alignItems="flex-start">
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <Flex alignItems="center">
            {transaction.status === TransactionStatusEnum.Succeeded ? (
              <Icons.SuccessCheckIcon height="24px" fill={color} />
            ) : (
              <Icons.ClearIcon height="24px" fill={color} />
            )}
            <Text ml="8px" color={color} fontSize="16px" bold>
              {transaction.type === TransactionTypeEnum.Deposit ? (
                <Trans>Deposit Confirmed!</Trans>
              ) : transaction.status === TransactionStatusEnum.Succeeded ? (
                <Trans>Withdrawal Completed!</Trans>
              ) : (
                <Trans>Withdrawal Failed!</Trans>
              )}
            </Text>
          </Flex>

          <Flex mt="4px" ml="32px" alignItems="center">
            <TokenLogo token={token} width="20px" />
            <Text ml="6px" fontSize="14px">
              {displayAmount} {token.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default CompletedTransaction
