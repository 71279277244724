import { Web3Provider } from '@ethersproject/providers'
import React from 'react'
import useSWRImmutable from 'swr/immutable'
import { WagmiConfig, useAccount, useNetwork } from 'wagmi'

const Web3LibraryContext = React.createContext<Web3Provider | undefined>(undefined)

const Web3LibraryProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { connector } = useAccount()
  const { chain } = useNetwork()
  const { data: library } = useSWRImmutable(connector && ['web3-library', connector, chain], async () => {
    const provider = await connector?.getProvider()
    return new Web3Provider(provider)
  })

  return <Web3LibraryContext.Provider value={library}>{props.children}</Web3LibraryContext.Provider>
}

export const WagmiProvider = (props: React.PropsWithChildren<any>) => {
  return (
    <WagmiConfig config={props.config}>
      <Web3LibraryProvider>{props.children}</Web3LibraryProvider>
    </WagmiConfig>
  )
}
