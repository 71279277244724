import { FetchingStatus } from 'config/constants'
import useActionQueryListener from 'hooks/useActionQueryListener'
import useAutoRedeemBonus from 'hooks/useAutoRedeemBonus'
import useDebounce from 'hooks/useDebounce'
import { useInitChatbox } from 'hooks/useInitChatbox'
import { useLanguageChangeEventListener } from 'hooks/useLanguageChangeEventListener'
import { useUpdateScreenHeight } from 'hooks/useUpdateScreenHeight'
import { useRouter } from 'next/router'
import AuthenticationProvider from 'providers/AuthenticationProvider'
import InitialUserProfile from 'providers/InitialUserProfile'
import UserBackgroundService from 'providers/UserBackgroundService'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppStatus, useMenuToggleUpdater, usePollTokenUsdPrices } from 'state/app/hooks'
import { useDailyCheckinInitial } from 'state/bonus/hooks/dailyReward'
import { usePollSystemData } from 'state/system/hooks'

export const StateUpdater = () => {
  usePollSystemData()

  const { t } = useTranslation()

  useEffect(() => {
    console.info(`%c${t('Stop!')}`, 'color: red; font-size: 40px; font-weight: 700')
    console.info(
      `%c${t(
        'This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a {{domain}} feature or "hack" someone\'s account, it is a scam and will give them access to your {{domain}} account.',
        { domain: 'HunnyPlay.io' },
      )}`,
      'font-size: 16px;',
    )
  }, [])

  const { status } = useAppStatus()

  return (
    <AuthenticationProvider>
      {status !== FetchingStatus.Banned &&
        status !== FetchingStatus.Maintenance &&
        status !== FetchingStatus.Failed && (
          <InitialUserProfile>
            <UserBackgroundService />
          </InitialUserProfile>
        )}
    </AuthenticationProvider>
  )
}

export const GlobalUpdater = () => {
  const { isFinishFetch, status } = useAppStatus()
  const router = useRouter()
  const { i18n } = useTranslation()

  const isAppReady = useDebounce(isFinishFetch && router.locale === i18n.language, 500)

  useUpdateScreenHeight()
  useMenuToggleUpdater()
  useInitChatbox()
  usePollTokenUsdPrices()
  useLanguageChangeEventListener()

  return isAppReady && status === FetchingStatus.Fetched && <AppHandler />
}

const AppHandler = React.memo(() => {
  useAutoRedeemBonus()
  useActionQueryListener()
  useDailyCheckinInitial()

  return null
})
