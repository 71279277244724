import { useEffect } from 'react'
import ProfileService from 'services/ProfileService'
import { useAppDispatch } from 'state'
import { useAuth, useUserInfo } from 'state/auth/hooks'
import { updateUserRegisteredCountry } from 'state/profile/actions'
import { useRequest } from './useRequest'

export const useInitializeUserRegisterCountry = () => {
  const { isSigned } = useAuth()
  const dispatch = useAppDispatch()
  const { execute } = useRequest()
  const { displayName } = useUserInfo()

  useEffect(() => {
    if (isSigned) {
      const fetch = async () => {
        const response = await execute(ProfileService.findUserData(displayName))
        if (response?.data) {
          dispatch(
            updateUserRegisteredCountry({
              registeredCountry: response.data.registerCountry,
            }),
          )
        }
      }

      fetch()
    } else {
      dispatch(
        updateUserRegisteredCountry({
          registeredCountry: null,
        }),
      )
    }
  }, [isSigned, displayName])
}

